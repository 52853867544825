<script setup>
import { useAuthStore } from "~~/store/auth";

const auth = useAuthStore();
const route = useRoute();

function isCurrent(path) {
  return route.fullPath.startsWith(path);
}
</script>

<template>
  <nav class="main-nav">
    <NuxtLink 
      class="main-nav__link" 
      v-bind:class="{'main-nav__link--current': isCurrent('/auth/contacts')}" 
      to="/auth/contacts"
      v-if="auth.isAdmin">
        Contacts
    </NuxtLink>
    <NuxtLink 
      class="main-nav__link" 
      v-bind:class="{'main-nav__link--current': isCurrent('/auth/users')}" 
      to="/auth/users"
      v-if="auth.isAdmin">
        Users
    </NuxtLink>
  </nav>
</template>

<style lang="scss">
.main-nav {
  display: flex;
}

.main-nav__link {
  @include menu-item;

  & {
    display: block;
    margin: 0 0.8rem;
  }
}

.main-nav__link--current {
  text-decoration: underline;
  text-decoration-thickness: var(--s-border-width);
  text-underline-offset: 0.24em;
}

@include breakpoint('phone') {
  .main-nav {
    display: block;
  }

  .main-nav__link {
    color: var(--c-menu-text);
    width: auto;
    margin: 2rem 0;
  }

  a.main-nav__link--current {
    color: var(--c-menu-text);
  }
}
</style>
