<script setup>
import { useAuthStore } from "~~/store/auth";

const route = useRoute();
const store = useAuthStore();

const isLoggedIn = computed(() => {
  return store.isLoggedIn;
})
const isExpanded = computed(() => {
  return route.path.startsWith('/login') 
    || route.path === '/auth' 
    || route.path === '/auth/';
})
</script>

<template>
  <header class="page-header" v-bind:class="{'page-header--expanded': isExpanded}">
    <NuxtLink class="page-header__logo" to="/auth/">
      <LayoutBrand />
    </NuxtLink>
    <div class="page-header__menu" v-if="isLoggedIn">
      <LayoutMainMenu />
    </div>
  </header>
</template>

<style lang="scss">
.page-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: var(--s-page-bezel-y);
  margin-bottom: 4.4rem;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: var(--s-page-bezel-y);
    right: 0;
    width: 2rem;
    height: 2rem;
    border-top: 1px solid var(--c-text);
    border-right: 1px solid var(--c-text);
  }
}

.page-header__logo {
  display: inline-block;
  width: 16.6666%;
  transition: width 200ms cubic-bezier(0.22, 1, 0.36, 1);
}

.page-header--expanded {
  margin-bottom: 14rem;

  .page-header__logo {
    width: 41.6666%;

    @include breakpoint('phone') {
      width: 64%;
    }
  }
}

@include breakpoint('phone') {
  .page-header,
  .page-header--expanded {
    align-items: center;
    margin-bottom: 2.4rem;
  }

  .page-header__logo,
  .page-header--expanded .page-header__logo {
    width: 64%;
  }
}
</style>
