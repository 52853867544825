<script setup>
import { useAuthStore } from "~~/store/auth";

const store = useAuthStore();
const roles = await getUserRoles();
store.setUserRoles(roles);

</script>

<template>
  <div class="page">
    <NuxtLoadingIndicator color="#6A1BCD" v-bind:height="2" />
    <div class="page__start">
      <LayoutPageHeader />
    </div>
    <main class="page__main">
      <slot />
    </main>
    <div class="page__end">
      <LayoutPageFooter />
      <LayoutGlobalNotifications />
    </div>
  </div>
</template>

<style lang="scss">
@import '@/styles/static/main';

.page {
  @include container();

  & {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}

.page__start,
.page__end {
  flex: 0 0 auto;
}

.page__main {
  flex: 1 1 auto;
}

.page__end {
  padding-top: 11.2rem;
}

</style>