<script setup>
import { useNotificationStore } from '~~/store/notification';

const store = useNotificationStore();

const notifications = computed(() => {
  return store.notifications;
})

function onCancel(id) {
  store.remove(id);
}


</script>

<template>
  <ul class="global-notifications">
    <li 
      class="global-notifications__notification"
      v-bind:class="`global-notifications__notification--${notification.type}`"
      v-for="notification in notifications"
      v-bind:key="notification.id">
        <div class="global-notifications__message">
          <h2 class="global-notifications__notification-title">{{ notification.title }}</h2>
          {{ notification.message }}
        </div>

        <div class="global-notifications__actions">
          <UiButton 
            icon="close" 
            size="minor" 
            type="text" 
            v-bind:iconOnly="true" 
            v-on:click="onCancel(notification.id)"
            aria-label="cancel notification">
          </UiButton>
        </div>
        
    </li>
  </ul>
</template>

<style lang="scss">
.global-notifications {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  list-style: none;
  z-index: var(--z-notification);
}

.global-notifications__notification {
  --c-text: var(--c-notification);
  --c-background: var(--c-notification-text);

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  background-color: var(--c-background);
  color: var(--c-text);
  margin-top: 0.2rem;
}

.global-notifications__notification--error {
  --c-text: var(--c-notification-error-text);
  --c-background: var(--c-notification-error);
}

.global-notifications__message {
  flex: 1 1 auto;
}

.global-notifications__actions {
  flex: 0 0 auto;
}

.global-notifications__actions .ui-button {
  --c-text: var(--c-text);
}
</style>
