<script setup>
const isExpanded = ref(false);

function onToggle() {
  isExpanded.value = !isExpanded.value;
}

const buttonIcon = computed(() => {
  return isExpanded.value ? 'close' : 'menu';
})
</script>

<template>
  <div class="main-menu" v-bind:class="{'main-menu--expanded': isExpanded}">
    <UiButton 
      class="main-menu__button" 
      v-bind:icon="buttonIcon" 
      type="text" 
      v-bind:iconOnly="true" 
      v-on:click="onToggle">
    </UiButton>
    <div class="main-menu__wrap">
      <div class="main-menu__content">
        <LayoutMainNav class="main-menu__main-nav" />
        <LayoutUserMenu class="main-menu__user-menu" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.main-menu__button {
  display: none;
}

.main-menu__content {
  display: flex;
  align-items: center;
}

// mobile menu
@include breakpoint('phone') {
  .main-menu__button {
    display: block;
  }

  .main-menu__wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    box-sizing: border-box;
    background-color: var(--c-menu-background);
    overflow: hidden;
    pointer-events: none;
  }

  .main-menu__content {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    color: var(--c-menu-text);
  }

  // expanded
  .main-menu--expanded {
    position: relative;
    z-index: var(--z-menu);

    .main-menu__wrap {
      height: 100%;
      padding: 8rem var(--s-modal-bezel-x) 3.2rem;
      pointer-events: all;
    }

    .ui-button.main-menu__button {
      position: fixed;
      top: var(--s-modal-bezel-y);
      right: var(--s-modal-bezel-x);
      color: var(--c-menu-text);
      z-index: 1;

      &:focus {
        color: var(--c-menu-text);
      }
    }
  }
}
</style>
