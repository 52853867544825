<script setup lang="ts">
  const config = useRuntimeConfig();
</script>

<template>
  <footer class="page-footer">
    <p class="page-footer__app-name">Projectplace</p>
    <p class="page-footer__meta">
      v{{ config.public.appVersion }}<br>
      © Eggert+Friends LLC, Zurich
    </p>
  </footer>
</template>

<style lang="scss">
$s-corner: 2rem;

.page-footer {
  @include small-text;
  
  & {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: $s-corner 0 calc(var(--s-page-bezel-y) * 0.5);
    line-height: 1;
  }

  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: $s-corner;
    height: $s-corner;
  }

  &::before {
    left: 0;
    border-bottom: 1px solid var(--c-text);
    border-left: 1px solid var(--c-text);
  }

  &::after {
    right: 0;
    border-right: 1px solid var(--c-text);
    border-bottom: 1px solid var(--c-text);
  }

  .page-footer__app-name,
  .page-footer__meta {
    margin-top: 0.8rem;
  }

  .page-footer__app-name {
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  .page-footer__meta {
    text-align: right;
  }
}
</style>
