<script setup>
import { useAuthStore } from "~~/store/auth";

const store = useAuthStore();

function onLogout(event) {
  event.preventDefault();
  store.logout();
  navigateTo('/login')
}

const user = computed(() => {
  return store.user;
})
</script>

<template>
  <div class="user-menu">

    <UiDropdown class="user-menu__dropdown" type="text" size="minor">
      <template v-slot:default>
        {{ user.name }}
      </template>
      <template v-slot:list>
        <NuxtLink to="/auth/users/profile">
          <UiDropdownItem size="minor" icon="settings">User profile</UiDropdownItem>
        </NuxtLink>
        <UiDropdownItem size="minor" icon="exit" v-on:click="onLogout">Log out</UiDropdownItem>
      </template>
    </UiDropdown>

    <aside class="user-menu__flat">
      <NuxtLink class="user-menu__link" to="/auth/users/profile">
        User profile
      </NuxtLink>
      <a href="#" class="user-menu__link" v-on:click="onLogout">Log out</a>
    </aside>
  </div>
</template>

<style lang="scss">
.user-menu__dropdown {

}

.user-menu__flat {
  display: none;
}

@include breakpoint('phone') {
  .user-menu__dropdown {
    display: none;
  }

  .user-menu__flat {
    display: flex;
    gap: 3.2rem;
  }

  .user-menu__link {
    @include user-menu-item;

    & {
      color: var(--c-menu-text);
    }
  }

  a.user-menu__link--current {
    color: var(--c-menu-text);
  }
}
</style>
